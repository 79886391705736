/**
 * Created with JetBrains PhpStorm.
 * User: gschulz
 * Date: 09.09.13
 * Time: 17:14
 */
let MathCalculation = (function () {

    let _private = {};

    _private.instance = this;

    this.course = function (latStart, lonStart, latEnd, lonEnd) {
        let convert = new MathConvert();
        latStart    = convert.deg2rad(latStart);
        latEnd      = convert.deg2rad(latEnd);
        lonStart    = convert.deg2rad(lonStart);
        lonEnd      = convert.deg2rad(lonEnd);
        let dLong   = lonEnd - lonStart;
        let dPhi    = Math.log(
            Math.tan(latEnd / 2 + (Math.PI / 4)) / Math.tan(latStart / 2 + (Math.PI / 4))
        );
        return Math.fmod(convert.rad2deg(Math.atan2(dLong, dPhi)) + 360, 360);
    };

    this.windCorrectionAngle = function (windSpeed, windDirection, trueCourse, trueAirSpeed) {
        let convert = new MathConvert();
        windDirection = convert.deg2rad(windDirection);
        trueCourse    = convert.deg2rad(trueCourse);
        if (trueAirSpeed === 0) {
            return 0;
        }
        return convert.rad2deg(Math.asin(windSpeed * Math.sin(windDirection - Math.PI - trueCourse) / trueAirSpeed));
    };


    /**
     *
     *
     *
     * @param lat1
     * @param lon1
     * @param lat2
     * @param lon2
     * @param outputMode
     * @returns {*}
     */
    this.distance = function (lat1, lon1, lat2, lon2, outputMode) {
        let convert = new MathConvert();
        if ((lat1 === 0 && lon1 === 0) || (lat2 === 0 && lon2 === 0)) {
            return 0;
        }
        outputMode = _private.instance.defaultValue(outputMode, 'km');
        let a = 6378137,
            b = 6356752.314245,
            f = 1 / 298.257223563; // WGS-84 ellipsoid params
        let L = convert.deg2rad(lon2 - lon1);
        let U1 = Math.atan((1 - f) * Math.tan(convert.deg2rad(lat1)));
        let U2 = Math.atan((1 - f) * Math.tan(convert.deg2rad(lat2)));
        let sinU1 = Math.sin(U1),
            cosU1 = Math.cos(U1);
        let sinU2 = Math.sin(U2),
            cosU2 = Math.cos(U2);

        let lambda = L, lambdaP, iterLimit = 100, cosSqAlpha, sinSigma, cosSigma, cos2SigmaM, sigma;
        //console.log(Math.abs(lambda - lambdaP) > 1e-12);
        do {
            let sinLambda = Math.sin(lambda), cosLambda = Math.cos(lambda);
            sinSigma = Math.sqrt((cosU2 * sinLambda) * (cosU2 * sinLambda) + (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda) * (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda));
            if (sinSigma === 0) return 0; // co-incident points
            cosSigma = sinU1 * sinU2 + cosU1 * cosU2 * cosLambda;
            sigma = Math.atan2(sinSigma, cosSigma);
            let sinAlpha = cosU1 * cosU2 * sinLambda / sinSigma;
            cosSqAlpha = 1 - sinAlpha * sinAlpha;

            cos2SigmaM = cosSigma - 2 * sinU1 * sinU2 / cosSqAlpha;
            if (isNaN(cos2SigmaM)) cos2SigmaM = 0; // equatorial line: cosSqAlpha=0 (§6)
            let C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
            lambdaP = lambda;
            lambda = L + (1 - C) * f * sinAlpha * (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
        } while (Math.abs(lambda - lambdaP) > 1e-12 && --iterLimit > 0);

        if (iterLimit === 0) return NaN;
        let uSq = cosSqAlpha * (a * a - b * b) / (b * b);
        let A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
        let B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));
        let deltaSigma = B * sinSigma * (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) - B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
        let erg = (b * A * (sigma - deltaSigma)) / 1000;

        switch (outputMode) {
            case 'nm':
                erg = convert.kmToNm(erg);
                break;
            case 'm':
                erg = erg * 1000;
                break;
        }
        return erg;


    };

    this.toPoint = function (start, brng, dist) {
        let convert = new MathConvert();
        let a                                                 = 6378137,
            b                                                 = 6356752.3142,
            f                                                 = 1 / 298.257223563, // WGS-84 ellipsiod
            s                                                 = dist,
            alpha1                                            = convert.deg2rad(brng),
            sinAlpha1                                         = Math.sin(alpha1),
            cosAlpha1                                         = Math.cos(alpha1),
            tanU1                                             = (1 - f) * Math.tan(convert.deg2rad(start.lat)),
            cosU1 = 1 / Math.sqrt((1 + tanU1 * tanU1)), sinU1 = tanU1 * cosU1,
            sigma1                                            = Math.atan2(tanU1, cosAlpha1),
            sinAlpha                                          = cosU1 * sinAlpha1,
            cosSqAlpha                                        = 1 - sinAlpha * sinAlpha,
            uSq                                               = cosSqAlpha * (a * a - b * b) / (b * b),
            A                                                 = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq))),
            B                                                 = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq))),
            sigma                                             = s / (b * A),
            sigmaP                                            = 2 * Math.PI;
        while (Math.abs(sigma - sigmaP) > 1e-12) {
            let cos2SigmaM = Math.cos(2 * sigma1 + sigma),
                sinSigma   = Math.sin(sigma),
                cosSigma   = Math.cos(sigma),
                deltaSigma = B * sinSigma *
                    (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)
                        - B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
            sigmaP         = sigma;
            sigma          = s / (b * A) + deltaSigma;
        }
        let tmp    = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1,
            lat2   = Math.atan2(sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
                (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp)),
            lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1),
            C      = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha)),
            L      = lambda - (1 - C) * f * sinAlpha * (sigma + C * sinSigma *
                (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM))),
            revAz  = Math.atan2(sinAlpha, -tmp); // final bearing
        return {lat: convert.rad2deg(lat2), lon: start.lon + convert.rad2deg(L)};
    };


    this.trueHeading = function (trueCourse, windCorrectionAngle) {
        return (trueCourse - windCorrectionAngle);
    };

    this.trueCourse = function (latStart, lonStart, latEnd, lonEnd) {
        let convert = new MathConvert();
        latStart    = convert.deg2rad(latStart);
        latEnd      = convert.deg2rad(latEnd);
        lonStart    = convert.deg2rad(lonStart);
        lonEnd      = convert.deg2rad(lonEnd);

        let distanceLon = lonEnd - lonStart;

        let distancePhi = Math.log(Math.tan(latEnd / 2.0 + (Math.PI / 4)) / Math.tan(latStart / 2.0 + (Math.PI / 4)));


        if (Math.abs(distanceLon) > Math.PI) {
            if (distanceLon > 0.0) {
                distanceLon = -(2.0 * Math.PI - distanceLon);
            } else {
                distanceLon = (2.0 * Math.PI + distanceLon);
            }
        }
        return Math.fmod(convert.deg2rad(Math.atan2(distanceLon, distancePhi)) + 360.0, 360.0);
    };

    this.groundSpeed = function (windSpeed, windDirection, trueCourse, trueAirSpeed) {
        let convert = new MathConvert();
        let sin2    = Math.sin(convert.deg2rad(windDirection) - Math.PI - convert.deg2rad(trueCourse));
        if (sin2 === 0) {
            return trueAirSpeed - windSpeed;
        }
        let windCorrectionAngle = _private.instance.windCorrectionAngle(windSpeed, windDirection, trueCourse, trueAirSpeed);
        let sin1                = Math.sin(new MathConvert().deg2rad(windDirection) - Math.PI - convert.deg2rad(trueCourse)
            + convert.deg2rad(windCorrectionAngle));

        let trueHeading = _private.instance.trueHeading(trueCourse, windCorrectionAngle);
        if (windDirection === trueHeading) {
            return trueAirSpeed - windSpeed;
        }
        return trueAirSpeed * (sin1 / sin2);
    };


    this.distanceMidpoint = function (latStart, lonStart, latEnd, lonEnd) {
        let convert = new MathConvert();
        latStart    = convert.deg2rad(latStart);
        latEnd      = convert.deg2rad(latEnd);
        lonStart    = convert.deg2rad(lonStart);
        lonEnd      = convert.deg2rad(lonEnd);

        let distanceLon = lonEnd - lonStart;

        let Bx = Math.cos(latEnd) * Math.cos(distanceLon);
        let By = Math.cos(latEnd) * Math.sin(distanceLon);

        let latMidpoint = Math.atan2(
            Math.sin(latStart) + Math.sin(latEnd),
            Math.sqrt((Math.cos(latStart) + Bx) * (Math.cos(latStart) + Bx) + By * By));
        let lonMidpoint = lonStart + Math.atan2(By, Math.cos(latStart) + Bx);

        latMidpoint = convert.deg2rad(latMidpoint);
        lonMidpoint = convert.deg2rad(lonMidpoint);
        return {
            'lat': latMidpoint,
            'lon': lonMidpoint
        };
    };


    this.estimatedTimeEnRoute = function (groundSpeed, distance) {
        return (distance / (groundSpeed * new MathConvert().MILE_NAUTIC) * 3600);
    };

    this.tripFuelKg = function (ete, fuelPerHour) {
        return ete / 3600 * fuelPerHour;
    };

    this.contingencyFuelKg = function (fuelEnRoute, contingencyFuelPercent) {
        return fuelEnRoute * contingencyFuelPercent;
    };

    this.reserveFuelKg = function (fuelPerHour, reserveFuelTime) {
        return reserveFuelTime / 3600 * fuelPerHour;
    };


    this.flightEmptyWeight = function (basicEmptyWeight, equipments) {
        let flightEmptyWeight = basicEmptyWeight;
        $.each(equipments, function (i, equipment) {
            if (_.isUndefined(equipment.active) || !equipment.active ||
                _.isUndefined(equipment.weight) || _.isNaN(equipment.weight)) {
                return;
            }
            flightEmptyWeight += equipment.weight;
        });
        return flightEmptyWeight;
    };


    this.defaultValue = function (variable, defaultValue) {
        if (_.isUndefined(variable) || $.trim(variable) === '') {
            variable = defaultValue;
        }
        return variable;
    };


});